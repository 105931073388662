<template>
  <div class="H5Login" :style="'height:' + Height + 'px'">
    <div @click="Jump" class="Bav">
      <div class="wrap">
        <div class="logo">
          <span>Credit Lab</span>
        </div>
      </div>
    </div>
    <div class="ChangeState">
      <div class="item" @click="ChangeState(0)">
        登录
        <div
          class="title-content-underLine"
          :style="State == 0 ? 'transform: scaleX(1);' : ''"
        ></div>
      </div>
      <div class="item" @click="ChangeState(1)">
        注册
        <div
          class="title-content-underLine"
          :style="State == 1 ? 'transform: scaleX(1);' : ''"
        ></div>
      </div>
    </div>
    <div class="Contents">
      <div class="Form" v-if="State == 0">
        <van-form @submit="onLoginSubmit">
          <van-field
            v-model="loginForm.username"
            name="账号"
            label="账号"
            placeholder="账号"
            :rules="[{ required: true, message: '请填写账号' }]"
          />
          <van-field
            style="margin-top: 20px"
            v-model="loginForm.password"
            type="password"
            name="密码"
            label="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
          <div class="agreement">
            登录并使用比赛网站需遵守<span @click="JumpUserAgreement" style="color: rgb(0, 100, 200)"
              >《用户注册协议》</span
            >
          </div>
          <div style="margin-top: 96px">
            <van-button round block type="info" native-type="submit"
              >登录</van-button
            >
          </div>
        </van-form>
      </div>
      <div class="Form" v-if="State == 1">
        <van-form @submit="onSubmit">
          <van-field
            v-model="RegisterForm.username"
            name="手机号"
            label="手机号"
            placeholder="请填写手机号"
            :rules="[{ required: true, message: '请填写手机号' }]"
          />
          <van-field
            style="margin-top: 20px"
            v-model="RegisterForm.password"
            type="password"
            name="密码"
            label="密码"
            placeholder="请填写密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
          <van-field
            style="margin-top: 20px"
            v-model="RegisterForm.realname"
            name="姓名"
            label="姓名"
            placeholder="请填写姓名"
            :rules="[{ required: true, message: '请填写姓名' }]"
          />
          <van-field
            style="margin-top: 20px"
            v-model="RegisterForm.nickname"
            name="昵称"
            label="昵称"
            placeholder="请填写昵称"
            :rules="[{ required: true, message: '请填写昵称' }]"
          />
          <van-field
            style="margin-top: 20px"
            v-model="RegisterForm.student_num"
            name="学号"
            label="学号"
            placeholder="请填写学号"
            :rules="[{ required: true, message: '请填写学号' }]"
          />
          <van-field
            style="margin-top: 20px"
            v-model="RegisterForm.school_name"
            name="学校"
            label="学校"
            placeholder="请填写学校"
            :rules="[{ required: true, message: '请填写学校' }]"
          />
          <van-field
            style="margin-top: 20px"
            v-model="RegisterForm.specialty"
            name="专业"
            label="专业"
            placeholder="请填写专业"
            :rules="[{ required: true, message: '请填写专业' }]"
          />
          <van-field
            style="margin-top: 20px"
            readonly
            clickable
            name="picker"
            :value="RegisterForm.grade"
            label="年级"
            placeholder="点击选择年级"
            :rules="[{ required: true, message: '请选择年级' }]"
            @click="showPicker = true"
          />
          <van-popup v-model="showPicker" position="bottom">
            <van-picker
              show-toolbar
              :columns="gradeList"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>

          <van-field
            style="margin-top: 20px"
            readonly
            clickable
            name="picker"
            :value="RegisterForm.education_background"
            label="学历"
            placeholder="点击选择学历"
            :rules="[{ required: true, message: '请选择学历' }]"
            @click="education = true"
          />
          <van-popup v-model="education" position="bottom">
            <van-picker
              show-toolbar
              :columns="educationBackgroundList"
              @confirm="onConfirmeducation"
              @cancel="education = false"
            />
          </van-popup>
          <van-field
            style="margin-top: 20px"
            v-model="RegisterForm.email"
            name="邮箱"
            label="邮箱"
            placeholder="请填写邮箱"
            :rules="[{ required: true, message: '请填写邮箱' }]"
          />
          <van-field
            style="margin-top: 20px"
            v-model="RegisterForm.faculty_adviser"
            name="指导老师"
            label="指导老师"
            placeholder="请输入对应指导老师；没有则填‘无’"
            :rules="[{ required: true, message: '请输入对应指导老师，没有则填‘无’' }]"
          />
          <div class="items" style="margin-top: 20px">
            <div class="items_view">图片验证码</div>
            <input
              type="text"
              class="text"
              v-model="RegisterForm.ImgVerification"
              placeholder="请输入图片验证码"
            />
            <img
              style="height: 40px; width: 120px"
              @click="ChangeiMG"
              :src="VerificationCodeImgUrl"
              alt=""
            />
          </div>
          <div class="items" style="margin-top: 20px">
            <div class="items_view">短信验证码</div>
            <input
              type="text"
              class="text"
              v-model="RegisterForm.verification_code"
              placeholder="请输入短信验证码"
            />
            <van-button
              style="height: 40px; width: 220px"
              @click="GetPhoneVerification"
              :disabled="!disabledCodeBtn"
              type="info"
              >{{ codeText }}</van-button
            >
          </div>

          <div style="margin-top: 16px">
            <van-button
              round
              block
              type="info"
              native-type="submit"
              >注册</van-button
            >
          </div>
          <div class="agreement" style="text-align: center;">
              <div style="display: flex;margin: 0 auto;justify-content: center;align-items: center;">
                <van-checkbox style="text-align: center;display: flex;" v-model="checked" shape="square">同意遵守</van-checkbox><span @click="JumpUserAgreement" style="color: rgb(0, 100, 200)">《用户注册协议》</span>
              </div>
              新版《用户协议》已更新，请您及时查看
          </div>
          
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  send_graphic,
  phone_verification,
  register,
  //   login,
} from "../api/login";
import { Notify } from "vant";
export default {
  data() {
    return {
      Height: document.documentElement.clientHeight,
      State: 0,
      // 登录表单
      loginForm: {
        username: "",
        password: "",
      },
      RegisterForm: {},
      phone_verificationFrom: {
        phone_num: "",
        graphic_code: "",
      },
      checked:false,
      disabledCodeBtn: true,
      codeText: "获取验证码",
      gradeList: [],
      showPicker: false,
      educationBackgroundList: [
        "高中及以下",
        "大专",
        "本科",
        "硕士研究生",
        "博士研究生",
      ],
      education: false,
      VerificationCodeImgUrl: "",
    };
  },
  created() {
    this.getNf();
  },
  methods: {
    Jump() {
      this.$router.push("/");
    },
	
	JumpUserAgreement(){
		window.location.href="https://resource.delist.cn/用户注册协议V3.pdf";
	},
    // 切换图形验证码
    ChangeiMG() {
      this.Getsend_graphicImg();
    },
    // 图形验证码请求
    Getsend_graphicImg() {
      send_graphic().then((res) => {
        this.VerificationCodeImgUrl = "data:image/png;base64," + res;
      });
    },
    // 获取手机验证码
    GetPhoneVerification() {
      this.phone_verificationFrom.phone_num = this.RegisterForm.username;
      this.phone_verificationFrom.graphic_code =
        this.RegisterForm.ImgVerification;
      if (!this.phone_verificationFrom.phone_num) {
        Notify({ type: "warning", message: "请输入手机号" });
        return;
      } else {
        phone_verification(this.phone_verificationFrom)
          .then((res) => {
            if (res.code == 200) {
              this.countDown(60);
              Notify({ type: "success", message: "已发送短信" });
            } else {
            }
          })
          .catch(() => {});
      }
    },
    ChangeState(state) {
      if (state == 1) {
        this.RegisterForm = {};
        this.phone_verificationFrom = {
          phone_num: "",
          graphic_code: "",
        };
        this.ChangeiMG();
      }
      if (state == 0) {
        this.loginForm = {};
      }
      this.State = state;
    },
    // 登录
    onLoginSubmit() {
      this.$store
        .dispatch("Login", this.loginForm)
        .then(() => {
          this.loginForm = {};
          this.$router.push({
            path: this.redirect || "/PersonalInfoH5",
          });
        })
        .catch(() => {});
    },
    // 注册
    onSubmit() {
        if(!this.RegisterForm.verification_code){
            Notify({ type: 'danger', message: '请输入短信验证码' });
            return
        }
        if(!this.checked){
            Notify({ type: 'danger', message: '请阅读并勾选用户协议' });
            return
        }
      register({
        register_dict: this.RegisterForm,
        verification_code: this.RegisterForm.verification_code,
      })
        .then((res) => {
          if (res.code == 200) {
            Notify({ type: "success", message: "注册成功" });
            this.State = 1;
            this.RegisterForm = {};
          }
        })
        .catch(() => {});
    },
    getNf() {
      var nfOptionsArray = new Array();
      var years = new Date().getFullYear();
      for (let i = years - 9; i <= years; i++) {
        nfOptionsArray.push(i);
      }
      this.gradeList = nfOptionsArray;
      this.gradeList.reverse();
    },
    onConfirm(value) {
      this.RegisterForm.grade = value;
      this.showPicker = false;
    },
    onConfirmeducation(value) {
      this.RegisterForm.education_background = value;
      this.education = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.H5Login {
  width: 100%;
  // overflow-y: auto;
  overflow: hidden;
}
.Bav {
  width: 100%;
  height: 60px;
  line-height: 60px;
  .wrap {
    box-sizing: border-box;
    display: flex;
    width: 90%;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    .logo {
      margin: 0 auto;
      padding: 0 20px;
      font-size: 2em;
      font-weight: bold;
      color: #2b67ee;
      background-image: url("../assets/logo.png");
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-position: left center;
    }
  }
}
.ChangeState {
  margin-top: 30px;
  margin-bottom: 60px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  .item {
    width: 40%;
    height: 30px;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    line-height: 30px;
    position: relative;
    .title-content-underLine {
      position: absolute;
      left: 0;
      bottom: 0px;
      transform: scaleX(0);
      transform-origin: 50% 50%;
      transition: transform 0.3s ease;
      width: 100%;
      // border-bottom: 3px solid #ff6a00;
      border-bottom: 2px solid #2b67ee;
    }
  }
  .item:nth-child(1) {
    // border-right: 1px solid #ccc;
  }
}
.Contents {
  width: 100%;
  height: calc(100% - 180px);
  overflow-y: auto;
  .Form {
    width: 100%;
    margin-bottom: 40px;
    .agreement {
      margin: 10px;
    }
    .items {
      width: 100%;
      min-height: 40px;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      background: rgba(255, 255, 255, 1);
      // border-bottom: 1px solid #EDEFF2;
      padding-right: 10px;
      position: relative;
      i {
        vertical-align: middle;
        font-size: 1.2em;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(219, 27, 28, 1);
        margin-right: 10px;
      }
      view {
        font-size: 1.2em;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
      }
      input {
        padding-right: 20px;
        width: 80%;
        height: 24px;
        color: #000;
        font-size: 1.1em;
        border: none;
      }
      .items_view {
        width: 210px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #646566;
        padding: 10px 16px;
        text {
          vertical-align: middle;
        }
      }
      .text {
        font-size: 1.2em;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(144, 147, 153, 1);
        // background: url(../../../static/images/RightIco.png) no-repeat center right;
        background-size: 14px 14px;
        padding-right: 10px;
      }
    }
  }
}
</style>